<template>
    <div class="d-flex flex-column justify-center align-center fill-height py-8 overflow-hidden">
        <v-card width="350">
            <v-card-title>
                <v-icon large color="primary" class="mr-2">mail</v-icon>
                Verify your e-mail
            </v-card-title>
            <v-card-text class="pt-5">
                <p>We have sent an invitation email to your email address</p>
                <p class="text-center secondary--text">{{ email }}</p>
                <p>Please check your inbox and click on the link in the e-mail to create your account.</p>
                <span class="d-block text-center caption">(you can close this page)</span>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'SignupSuccess',
    data() {
        return {
            email: ''
        }
    },
    created() {
        this.email = localStorage.getItem('nvLastEmail')
    },
    methods: {},
    computed: {},
    watch: {}
}
</script>

<style lang="scss" scoped>
@import '@/sass/signup.scss';
</style>
